import React from 'react';
import ChangeStatus from '@mui/icons-material/ImportExport';
import SendIcon from '@mui/icons-material/Send';
import RejectQuote from '@mui/icons-material/HighlightOff';
import { PaymentInputOption, QuoteStatus } from 'src/utils/constants/quotes';
import { getClientFullName } from 'src/utils/common';
import selectFormatDate from 'src/utils/selectDateFormat';
import dateFormatType from 'src/utils/dateFormatType';
import ArchiveIcon from '@mui/icons-material/Archive';
import FileOpenIcon from '@mui/icons-material/FileOpen';
import StatusIcon from 'src/components/StatusIcon';
import { GracePeriodValues } from 'src/utils/constants/distributors';
import {
  IconStatusAndColor,
  backgroundColorOfStatus
} from './constants/common';

export const QuoteActions = {
  SEE_DOCUMENT: 'SEE_DOCUMENT',
  CONTRACT_REQUEST: 'CONTRACT_REQUEST',
  SEND_TO_CONTRACT: 'SEND_TO_CONTRACT',
  REJECT_QUOTE: 'REJECT_QUOTE',
  ARCHIVE: 'ARCHIVE',
  ASSIGN_KAM: 'ASSIGN_KAM',
  ASSIGN_CLIENT: 'ASSIGN_CLIENT',
  SEND_TO_WHATSAPP: 'SEND_TO_WHATSAPP'
};

export const QuoteTypes = {
  MSI: 'MSI',
  NOR: 'NOR'
};

export const getAdminQuotesMenuOptions = (
  document,
  status,
  distributorProfile,
  pendingDocs,
  sentForClientApproval
) => {
  switch (status) {
    case QuoteStatus.IN_REVIEW:
      return [
        {
          name: QuoteActions.SEE_DOCUMENT,
          label: `ENUMS.QUOTE_ACTIONS.${QuoteActions.SEE_DOCUMENT}`,
          disabled: !document,
          icon: <FileOpenIcon />
        },
        {
          name: QuoteActions.SEND_TO_CONTRACT,
          label: `ENUMS.QUOTE_ACTIONS.${QuoteActions.SEND_TO_CONTRACT}`,
          icon: <SendIcon />,
          // this is a temporary solution, we need to check if the quote has pending documents
          /* disabled: pendingDocs ? Boolean(pendingDocs.number) : false, */
          message: pendingDocs ? pendingDocs.message : null
        },
        {
          name: QuoteActions.REJECT_QUOTE,
          label: `ENUMS.QUOTE_ACTIONS.${QuoteActions.REJECT_QUOTE}`,
          icon: <RejectQuote />
        }
      ];
    case QuoteStatus.DRAFT:
      if (!distributorProfile) {
        return [
          {
            name: QuoteActions.SEE_DOCUMENT,
            label: `ENUMS.QUOTE_ACTIONS.${QuoteActions.SEE_DOCUMENT}`,
            disabled: !document,
            icon: <FileOpenIcon />
          },
          {
            name: QuoteActions.SEND_TO_WHATSAPP,
            label: `ENUMS.QUOTE_ACTIONS.${QuoteActions.SEND_TO_WHATSAPP}`,
            icon: <SendIcon />
          },
          {
            name: QuoteActions.CONTRACT_REQUEST,
            label: !sentForClientApproval
              ? `ENUMS.QUOTE_ACTIONS.${QuoteActions.CONTRACT_REQUEST}`
              : `ENUMS.QUOTE_ACTIONS.RESEND_CONTRACT_REQUEST`,
            icon: <ChangeStatus />
          },
          {
            name: QuoteActions.ASSIGN_KAM,
            label: `ENUMS.QUOTE_ACTIONS.${QuoteActions.ASSIGN_KAM}`,
            icon: <ChangeStatus />
          },
          {
            name: QuoteActions.ARCHIVE,
            label: `ENUMS.QUOTE_ACTIONS.${QuoteActions.ARCHIVE}`,
            icon: <ArchiveIcon />
          }
        ];
      }
      return [
        {
          name: QuoteActions.SEE_DOCUMENT,
          label: `ENUMS.QUOTE_ACTIONS.${QuoteActions.SEE_DOCUMENT}`,
          disabled: !document,
          icon: <FileOpenIcon />
        },
        {
          name: QuoteActions.SEND_TO_WHATSAPP,
          label: `ENUMS.QUOTE_ACTIONS.${QuoteActions.SEND_TO_WHATSAPP}`,
          icon: <SendIcon />
        },
        {
          name: QuoteActions.CONTRACT_REQUEST,
          label: !sentForClientApproval
            ? `ENUMS.QUOTE_ACTIONS.${QuoteActions.CONTRACT_REQUEST}`
            : `ENUMS.QUOTE_ACTIONS.RESEND_CONTRACT_REQUEST`,
          icon: <ChangeStatus />
        }
      ];
    default:
      return [
        {
          name: QuoteActions.SEE_DOCUMENT,
          label: `ENUMS.QUOTE_ACTIONS.${QuoteActions.SEE_DOCUMENT}`,
          disabled: !document,
          icon: <FileOpenIcon />
        }
      ];
  }
};

export const getUserQuotesMenuOptions = (
  document,
  status,
  sentForClientApproval
) => {
  if (status === QuoteStatus.DRAFT) {
    return [
      {
        name: QuoteActions.SEE_DOCUMENT,
        label: `ENUMS.QUOTE_ACTIONS.${QuoteActions.SEE_DOCUMENT}`,
        disabled: !document,
        icon: <FileOpenIcon />
      },
      {
        name: QuoteActions.CONTRACT_REQUEST,
        label: !sentForClientApproval
          ? `ENUMS.QUOTE_ACTIONS.${QuoteActions.CONTRACT_REQUEST}`
          : `ENUMS.QUOTE_ACTIONS.RESEND_CONTRACT_REQUEST`,
        icon: <ChangeStatus />
      },
      {
        name: QuoteActions.ARCHIVE,
        label: `ENUMS.QUOTE_ACTIONS.${QuoteActions.ARCHIVE}`,
        icon: <ArchiveIcon />
      }
    ];
  }
  return [
    {
      name: QuoteActions.SEE_DOCUMENT,
      label: `ENUMS.QUOTE_ACTIONS.${QuoteActions.SEE_DOCUMENT}`,
      disabled: !document,
      icon: <FileOpenIcon />
    }
  ];
};

export const getBrandModelName = (brand, model) => `${brand.name} - ${model}`;
export const getQuoteFileName = (client, brand, model, createdAt) =>
  `Cotizacion - ${getClientFullName(client)} - Equipo ${getBrandModelName(
    brand,
    model
  )} - ${selectFormatDate(createdAt, dateFormatType.DAY)}.pdf`;

export const getInitialPaymentAmount = (
  priceWithIva,
  initialPayment,
  paymentInputOption
) => {
  if (paymentInputOption === PaymentInputOption.AMOUNT) {
    return initialPayment;
  }
  const initialPaymentAmount = priceWithIva * (initialPayment / 100);
  return initialPaymentAmount;
};

export const translateGracePeriodValueToDaysForFirstRent = (gracePeriod, t) => {
  if (gracePeriod < 0) {
    return `${t('ENUMS.DAYS_FOR_FIRST_RENT.SAME_DAY_AS_CONTRACT_STARTS')}`;
  }

  return `${gracePeriod + 30} ${t('COMMON.DAYS')}`;
};

export const selectBackGroundColorFromQuoteStatus = quote => {
  switch (quote.status) {
    case QuoteStatus.REJECTED:
      return backgroundColorOfStatus.REJECTED;
    case QuoteStatus.ARCHIVED:
      return backgroundColorOfStatus.ARCHIVED_OR_ACCEPTED;
    case QuoteStatus.ACCEPTED:
      return backgroundColorOfStatus.ARCHIVED_OR_ACCEPTED;
    case QuoteStatus.DRAFT:
      return backgroundColorOfStatus.DRAFT_OR_DEFAULT;
    default:
      return backgroundColorOfStatus.DRAFT_OR_DEFAULT;
  }
};

export const handleInitialPaymentChange = (e, setFieldValue) => {
  const amount = Number(e.target.value);
  setFieldValue('initialPayment', amount);
  if (amount === 0) {
    setFieldValue('gracePeriod', GracePeriodValues.SAME_DAY_AS_CONTRACT_STARTS);
  }
};

export const getQuoteStatusIcon = quote => {
  switch (quote.status) {
    case QuoteStatus.ACCEPTED:
      return (
        <StatusIcon
          description="ENUMS.QUOTE_STATUS.ACCEPTED"
          type={IconStatusAndColor.OK.status}
        />
      );

    case QuoteStatus.ARCHIVED:
      return (
        <StatusIcon
          description="ENUMS.QUOTE_STATUS.ARCHIVED"
          type={IconStatusAndColor.OK.status}
        />
      );
    case QuoteStatus.IN_REVIEW:
      return (
        <StatusIcon
          description="ENUMS.QUOTE_STATUS.IN_REVIEW"
          type={IconStatusAndColor.OK.status}
        />
      );
    case QuoteStatus.REJECTED:
      return (
        <StatusIcon
          description="ENUMS.QUOTE_STATUS.REJECTED"
          type={IconStatusAndColor.WARNING.status}
        />
      );
    case QuoteStatus.DRAFT:
      return (
        <StatusIcon
          description="ENUMS.QUOTE_STATUS.DRAFT"
          type={IconStatusAndColor.NONE.status}
        />
      );
    default:
      return (
        <StatusIcon
          description="COMMON.NO_DATA"
          type={IconStatusAndColor.NONE.status}
        />
      );
  }
};
