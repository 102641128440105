import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Typography, Grid, Box, Button } from '@mui/material';
import Breadcrumb from 'src/components/Breadcrumb';
import { useDispatch, useSelector } from 'react-redux';
import MoneyIcon from '@mui/icons-material/AttachMoney';
import { createContractFromQuote } from 'src/store/actions/contractActions';
import { formatAmount } from 'src/utils/formatAmout';
import {
  selectBackGroundColorFromQuoteStatus,
  getQuoteStatusIcon,
  QuoteTypes
} from 'src/utils/quotes';
import { selectAuthSystem } from 'src/store/selectors/authSelectors';
import SendIcon from '@mui/icons-material/Send';
import RejectIcon from '@mui/icons-material/HighlightOff';
import UserType from 'src/utils/constants/auth';
import { QuoteStatus } from 'src/utils/constants/quotes';
import ChangeStatus from '@mui/icons-material/ImportExport';
import { assignKamToQuote } from 'src/store/actions/quoteActions';
import { getDistributorProfileAssignedToClient } from 'src/store/actions/distributorActions';
import useStyles from 'src/views/client/ClientDetail/ContractsList/styles';
import SendQuoteToContractForm from '../SendQuoteToContractForm';
import RejectQuoteDialog from '../RejectQuoteDialog';
import AssignKamDialog from '../AssignKamDialog';
import SendQuoteToWhatsappDialog from '../SendQuoteToWhatsappDialog';

const QuoteActions = {
  QUOTE_TO_CONTRACT: 'QUOTE_TO_CONTRACT',
  REJECT_QUOTE: 'REJECT_QUOTE',
  ERROR_IN_VALIDATION: 'ERROR_IN_VALIDATION',
  ASSIGN_KAM: 'ASSIGN_KAM',
  SEND_TO_WHATSAPP: 'SEND_TO_WHATSAPP'
};

const Toolbar = ({ quote, className, loading, ...rest }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(null);
  const system = useSelector(selectAuthSystem);
  const [kamAssignedToClient, setKamAssignedToClient] = useState(null);
  const [rentAmount, setRentAmount] = useState('0');

  useEffect(() => {
    if (quote.rent) {
      setRentAmount(quote.rent);
    }
  }, [quote]);

  const handleCloseDialogs = () => {
    setOpen(null);
  };

  const handleOpenQuoteToContractForm = () => {
    setOpen(QuoteActions.QUOTE_TO_CONTRACT);
  };

  const handleOpenRejectQuoteForm = () => {
    setOpen(QuoteActions.REJECT_QUOTE);
  };

  const handleOpenSendQuoteToWhatsappDialog = () => {
    setOpen(QuoteActions.SEND_TO_WHATSAPP);
  };

  const handleSendQuoteToContract = useCallback(
    values => {
      dispatch(
        createContractFromQuote(
          { ...values, cat: Number(values.cat), quoteId: quote.id },
          handleCloseDialogs
        )
      );
    },
    [quote.id]
  );

  const handleOpenAssignKamDialog = () => {
    setOpen(QuoteActions.ASSIGN_KAM);
  };

  const handleSubmitAssignKam = useCallback(
    values => {
      dispatch(assignKamToQuote(quote.id, values, handleCloseDialogs));
    },
    [quote.id]
  );

  useEffect(() => {
    if (quote && quote.distributor && quote.client) {
      dispatch(
        getDistributorProfileAssignedToClient(
          quote.distributor.id,
          quote.client.id,
          setKamAssignedToClient
        )
      );
    }
  }, [quote]);

  return (
    <>
      <Box className={clsx(classes.root, className)} {...rest}>
        <Breadcrumb doNotRedirectIndexList={[1]} />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            flexWrap: 'wrap'
          }}
        >
          <Grid
            container
            sx={{
              display: 'flex',
              justifyContent: quote.term ? 'space-evenly' : 'flex-start',
              alignItems: 'center',
              flexWrap: 'wrap',
              marginTop: '30px'
            }}
            spacing={2}
            lg={6}
            md={6}
            xs={12}
          >
            {quote && quote.id && (
              <Grid
                item
                lg={5}
                sx={theme => ({
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  background: theme.palette.common.white,
                  borderRadius: theme.spacing(1),
                  padding: '16px',
                  position: 'relative',
                  left: quote.term ? '-24px' : '24px'
                })}
              >
                <Box
                  className={classes.contractStatusBox}
                  sx={{
                    backgroundColor: selectBackGroundColorFromQuoteStatus(quote)
                  }}
                >
                  {getQuoteStatusIcon(quote)}
                </Box>
                <Box>
                  <Typography
                    sx={{ marginBottom: 1, marginTop: 1 }}
                    color="textPrimary"
                    component="p"
                    variant="body2"
                  >
                    {t('COMMON.ID')}
                  </Typography>
                  <Typography color="primary" component="h2" variant="h2">
                    {quote.id}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    backgroundColor: 'silver',
                    color: 'white',
                    borderRadius: '8px',
                    padding: '5px 13px',
                    marginLeft: 5,
                    marginTop: 1,
                    fontSize: '0.8rem',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >
                  <Typography>
                    {quote.isMsiModality ? QuoteTypes.MSI : QuoteTypes.NOR}
                  </Typography>
                </Box>
              </Grid>
            )}
            {quote.rent && (
              <Grid
                item
                lg={5}
                sx={theme => ({
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  background: theme.palette.common.white,
                  borderRadius: theme.spacing(1),
                  padding: '16px'
                })}
              >
                <Box className={classes.transactionIcon}>
                  <MoneyIcon fill="#28A745" />
                </Box>
                <Box>
                  <Typography color="textPrimary" component="p" variant="body2">
                    {t('COMMON.RENT')}
                  </Typography>
                  <Typography color="primary" component="h2" variant="h2">
                    {formatAmount(rentAmount)}
                  </Typography>
                </Box>
              </Grid>
            )}
          </Grid>

          {!loading && (
            <Box className={classes.actionsContainer}>
              {system === UserType.ADMIN &&
                quote.status === QuoteStatus.IN_REVIEW && (
                  <>
                    <Button
                      onClick={handleOpenRejectQuoteForm}
                      variant="contained"
                      color="primary"
                      className={clsx(classes.buttons)}
                      sx={{ marginRight: 2 }}
                      startIcon={<RejectIcon color="error" />}
                    >
                      {t('ENUMS.QUOTE_ACTIONS.REJECT_QUOTE')}
                    </Button>
                    <Box>
                      <Button
                        onClick={handleOpenQuoteToContractForm}
                        variant="contained"
                        className={clsx(classes.buttons)}
                        color="primary"
                        startIcon={<SendIcon />}
                      >
                        {t('ENUMS.QUOTE_ACTIONS.SEND_TO_CONTRACT')}
                      </Button>
                    </Box>
                  </>
                )}
              {system === UserType.ADMIN &&
                quote.status === QuoteStatus.DRAFT &&
                !quote.distributorProfile && (
                  <>
                    <Button
                      onClick={handleOpenAssignKamDialog}
                      variant="contained"
                      className={clsx(classes.buttons)}
                      color="primary"
                      startIcon={<ChangeStatus />}
                    >
                      {t(`ENUMS.QUOTE_ACTIONS.${QuoteActions.ASSIGN_KAM}`)}
                    </Button>
                  </>
                )}
              {quote.status === QuoteStatus.DRAFT && (
                <>
                  <Button
                    onClick={handleOpenSendQuoteToWhatsappDialog}
                    variant="contained"
                    className={clsx(classes.buttons)}
                    color="primary"
                    startIcon={<SendIcon />}
                  >
                    {t(`ENUMS.QUOTE_ACTIONS.${QuoteActions.SEND_TO_WHATSAPP}`)}
                  </Button>
                </>
              )}
            </Box>
          )}
        </Box>
      </Box>
      {open === QuoteActions.QUOTE_TO_CONTRACT && (
        <SendQuoteToContractForm
          open={open === QuoteActions.QUOTE_TO_CONTRACT}
          handleClose={handleCloseDialogs}
          onSubmit={handleSendQuoteToContract}
          term={quote.term}
          initialPayment={quote.initialPayment}
          client={quote.client ?? quote?.clientData ?? {}}
          gracePeriod={quote.gracePeriod}
        />
      )}
      {open === QuoteActions.REJECT_QUOTE && (
        <RejectQuoteDialog
          open={open === QuoteActions.REJECT_QUOTE}
          onClose={handleCloseDialogs}
          quoteId={quote.id}
        />
      )}

      {open === QuoteActions.SEND_TO_WHATSAPP && (
        <SendQuoteToWhatsappDialog
          open={open === QuoteActions.SEND_TO_WHATSAPP}
          onClose={handleCloseDialogs}
          quoteId={quote.id}
          phone={quote.client ? quote.client.phone : quote.clientData?.phone}
        />
      )}
      {system === UserType.ADMIN &&
        quote.status === QuoteStatus.DRAFT &&
        !quote.distributorProfile &&
        open === QuoteActions.ASSIGN_KAM && (
          <AssignKamDialog
            open={open === QuoteActions.ASSIGN_KAM}
            onClose={handleCloseDialogs}
            distributorId={quote.distributor.id}
            onSubmit={handleSubmitAssignKam}
            kamAssignedToClient={kamAssignedToClient}
          />
        )}
    </>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string,
  quote: PropTypes.object.isRequired,
  loading: PropTypes.bool
};

export default Toolbar;
